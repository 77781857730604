<template>
  <div class="address-content">
    <div class="settlement_address" v-loading="data.loading">
      <div class="settlement_title rowBC">
        <p class="title">{{ $t('Address') }}</p>
        <a @click="addNewAddress" class="rowSC add-box-btn">
          <p class="add-ico"></p>
          <p>{{ $t('New shipping address') }}</p>
        </a>
      </div>
      <div class="address-list" id="address">
        <div v-if="data.addressList.length > 0" :class="data.addressList.length > 2 ? 'innerbox' : ''">
          <template :key="index" v-for="(addressItem, index) in data.addressList">
            <div class="address-item" :class="{ xuan: addressItem.id === data.defaultAddress.id }">
              <div class="rowBC wh100">
                <div>
                  <el-checkbox v-model="addressItem.checkbox" @change="setToDefault(index)" />
                </div>
                <div class="address-info" @click.stop="setToDefault(index)">
                  <div class="rowBC crux-box">
                    <div class="rowSC">
                      <div class="name text_hide2">{{ addressItem.consignee }}</div>
                      <div class="phone">+92 {{ addressItem.mobile }}</div>
                    </div>
                    <div class="rowSC default-box">
                      <p v-if="addressItem.defAddress" class="default-btn">{{ $t('Default') }}</p>
                      <!-- <p v-else class="default-btn set-default" @click.stop="setShippingAddress(addressItem, index)">{{ $t('Default') }}</p> -->
                      <div class="address-emit" @click.stop="editTheAddress(addressItem)"></div>
                    </div>
                  </div>
                  <div class="rowSS address-box">
                    <!-- <div class="address-ico"></div> -->
                    <div class="address">
                      {{ formattedAddress(addressItem.address, addressItem.city, addressItem.province) }}
                      <!-- addressItem.address, addressItem.city, addressItem.province -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="no-center rowCC">No Shipping Address</div>
      </div>
    </div>
  </div>

  <!-- 新增编辑地址弹窗 -->
  <addressViewDalog ref="addressViewDalogRef" :addressData="data.addressData" :modalTitle="data.modalTitle" @saveTheAddress="saveTheAddress"></addressViewDalog>
</template>

<script setup>
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, watchEffect, computed, nextTick, defineEmits } from 'vue'
  import api from '@/api'
  import JLTools from '@/util/JLTools'
  import addressViewDalog from './addressView-dialog'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['sync-default-address'])

  const data = reactive({
    loading: false,
    //收货地址列表
    addressList: [],
    //Modal框的地址信息
    addressData: {
      consignee: '',
      mobile: '',
      province: '',
      city: '',
      address: '',
      defAddress: false
    },
    defaultAddress: {},
    modalTitle: '',
    curIndex: 0
  })

  const addressViewDalogRef = ref()

  const formattedAddress = (address, city, province) => {
    const fullAddress = `${address},${city},${province}`
    return fullAddress.replace(/,+/g, ',')
  }

  // 查询地址
  const getAddressList = (type) => {
    if (data.loading) return
    data.loading = true
    let parms = {}
    parms['userId.equals'] = userStore.userInfo.userId
    parms.sort = 'lastModifiedDate,desc'
    api.address
      .handleAddress(parms)
      .then((res) => {
        userStore.setAddressList(res)
        // console.log(data.addressList, 'data.addressList地址列表')
        data.defaultAddress = {}
        const index = res.findIndex((item) => item.defAddress === true)
        if (index !== -1) { // 存在默认值，移到第一个选项并赋值给 data.defaultAddress
          [res[0], res[index]] = [res[index], res[0]]
          res.forEach((item) => (item.checkbox = item.defAddress === true))
          data.defaultAddress = res[0]
        } else {
          res.forEach((item) => (item.checkbox = false))
        }
        data.addressList = res
        syncDefaultAddress(data.defaultAddress, type)
      })
      .finally(() => {
        data.loading = false
      })
    proxy.$forceUpdate()
  }

  /**
   * 设置为默认地址
   * @param address
   * @param index
   */
  const setShippingAddress = (address, index) => {
    let title = proxy.$t('Set Shipping Address')
    let msg = proxy.$t('Do you want to use this shipping address?')
    proxy.$Message.confirm({
      title: title,
      msg: msg,
      okText: 'Use',
      onOk: () => {
        data.addressList[index].defAddress = true
        api.address.handleAddress(data.addressList[index], 'put').then((res) => {
          // console.log(res, '修改地址')
          getAddressList('emit')
        })
      },
      onCancel: () => {
        console.log('cancal')
      }
    })
  }

  /**
   * 设置为默认地址
   * @param address
   */
  const setToDefault = (index) => {
    data.addressList.map((item) => (item.checkbox = false))
    data.addressList[index].checkbox = true
    data.defaultAddress = data.addressList[index]
    // console.log(data.addressList, 'data.addressList')
    syncDefaultAddress()
  }

  // 同步更新默认地址查询运费
  const syncDefaultAddress = () => {
    // console.log("同步更新默认地址查询运费");
    $emit('sync-default-address', data.defaultAddress)
  }

  /**
   * 编辑地址
   * @param address
   */
  const editTheAddress = (address) => {
    data.modalTitle = proxy.$t('Edit shipping address')
    data.addressData = address
    showModal()
  }

  /**
   * 新增地址
   */
  const addNewAddress = () => {
    data.modalTitle = proxy.$t('New shipping address')
    data.addressData = {}
    data.addressData.defAddress = false
    showModal()
  }

  // 地址弹窗展示
  const showModal = async () => {
    await nextTick()
    addressViewDalogRef.value.showModal()
  }

  // 更改地址信息
  const changeAddress = (address) => {
    data.addressData.province = address.province
    data.addressData.city = address.city
    // data.addressData.country = address.district;
    // data.addressData.zipCode = address.zipCode;
    data.addressData.areaNo = address.areaNo
    console.log('changeAddress:', data.addressData)
  }

  /**
   * 保存地址
   */
  const saveTheAddress = (newAddress) => {
    newAddress.userId = userStore.userInfo.userId
    newAddress.country = ''
    newAddress.zipCode = '100111'
    let request
    console.log(newAddress, 'newAddress')
    if (JLTools.isNull(newAddress.id)) {
      request = api.address.handleAddress(newAddress, 'post')
    } else {
      request = api.address.handleAddress(newAddress, 'put')
    }
    request
      .then((res) => {
        addressViewDalogRef.value.hideModal()
        getAddressList('add')
      })
      .catch((error) => {
        proxy.$toast('Please fill all the information!')
      })
  }

  onMounted(() => {
    getAddressList('init')
  })
</script>

<style scoped lang="less">
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background-color: var(--btnBgColor);
  }
  .address-content {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #97979733;
  }
  .settlement_address {
    width: 100%;
    min-height: 100px;
    background: #fff;
    .settlement_title {
      width: 100%;
      padding: 15px 0;
      line-height: 20px;
      color: #000;
      font-weight: 600;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      .add-box-btn {
        color: var(--btnBgColor);
        cursor: pointer;
        .add-ico {
          width: 19px;
          height: 19px;
          margin-right: 4px;
          background: url('~@/assets/imgs/address/icons8_plus.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .address-list {
    width: 100%;
    min-height: 60px;
    padding: 5px 0;
    background: rgba(236, 236, 236, 0.6);
    box-sizing: border-box;
    border-radius: 4px;
    .innerbox {
      min-height: 60px;
      max-height: 220px;
      overflow-y: auto;
    }
    .no-center {
      width: 100%;
      height: 100%;
      line-height: 60px;
    }
  }

  .address-item {
    display: flex;
    position: relative;
    width: 100%;
    padding: 5px 12px;
    margin-top: 5px;
    border-bottom: 1px dashed #ccc;
    cursor: pointer;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    .address-info {
      width: 100%;
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      .crux-box {
        font-size: 14px;
        font-weight: 500;
        .default-box {
          min-height: 30px;
        }
        .name {
          max-width: 80px;
          margin-right: 10px;
        }
        .default-btn {
          padding: 1px 11px;
          margin-right: 8px;
          font-size: 12px;
          border-radius: 38px;
          border: 1px solid var(--btnBgColor);
          background: #fff;
          color: var(--btnBgColor);
        }
        .set-default {
          display: none;
        }
      }
      .address-box {
        margin-top: 10px;
        .address {
          width: fit-content;
          /* margin-left: 6px; */
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          word-break: break-word;
        }
      }
      .address-emit {
        width: 20px;
        height: 20px;
        background: url('https://cdn.qingmeidata.com/98313154.png') no-repeat;
        background-size: 100% 100%;
      }
      .address-ico {
        width: 24px;
        height: 24px;
        background: url('https://cdn.qingmeidata.com/b5c3fd12.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    &:hover .address-info {
      .set-default {
        display: block !important;
      }
    }
  }
</style>
